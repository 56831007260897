import React from "react";
import { useState } from "react";
import { Client } from "../services/_clientService";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "../components/Header/Header";
import Banner from "../components/Banner/Banner";
import FindMore from "../components/FindMore/FindMore";
import KnowAboutUs from "../components/KnowAboutUs/KnowAboutUs";
import Footer from "../components/Footer/Footer";
import { Snackbar } from "../hooks/Snackbar";
import FormModal from "../components/FormModal/FormModal";
import "./Home.css";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const { showSnackbar } = Snackbar();

  const allowedDomains = [
    "gmail.com",
    "yahoo.com",
    "ymail.com",
    "rocketmail.com",
    "outlook.com",
    "hotmail.com",
    "live.com",
    "msn.com",
    "icloud.com",
    "me.com",
    "mac.com",
    "aol.com",
    "zoho.com",
    "mail.com",
    "email.com",
    "usa.com",
    "post.com",
    "protonmail.com",
    "yandex.com",
    "yandex.ru",
    "gmx.com",
    "gmx.net",
    "fastmail.com",
    "fastmail.fm",
    "comcast.net",
    "verizon.net",
    "att.net",
    "sbcglobal.net",
    "rediffmail.com",
    "lycos.com",
    "tutanota.com",
    "hushmail.com",
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Full Name is required"),
      mobile: Yup.string()
        .matches(/^\+?\d{10,15}$/, "Phone number is not valid"),
        // .required("Phone No. is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      try {
        await new Client({
          payload: {
            fullName: values.name,
            email: values.email,
            mobile: values.mobile.toString(),
          },
        }).post();

        showSnackbar({ msg: "Submitted successfully", status: "success" });
      } catch (e) {
        showSnackbar({
          msg: "Something wrong! Please try again",
          // status: "success",
        });
      }
      closeModal();
    },
  });

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    formik.resetForm();
  };

  return (
    <div className="root">
      <Header openModal={openModal} />
      <div className="content">
        <Banner openModal={openModal} />
        <FindMore />
        <KnowAboutUs />

        <FormModal
          showModal={showModal}
          closeModal={closeModal}
          handleChange={formik.handleChange}
          formData={formik.values}
          submit={formik.handleSubmit}
          errors={formik.errors}
          touched={formik.touched}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Home;

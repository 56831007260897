import React, { useEffect } from "react";
import Button from "../Button/Button";
import "../FormModal/FormModal.css";

const FormModal = ({
  showModal,
  closeModal,
  handleChange,
  formData,
  submit,
  errors,
  touched,
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-10"
          onClick={closeModal}
        >
          <div className="mx-auto min-h-fit">
            <div
              className="relative w-fit my-6 mx-auto max-w-3xl min-w-[340px] mt-[180px] max-sm:mt-[36%]"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-w-[430px] w-full bg-white outline-none focus:outline-none">
                <div className="flex flex-col items-start justify-between rounded-t p-6 pt-6">
                  <h1 className="text-lg font-normal">Join our waitlist!</h1>
                  <p className="text-sm font-normal text-gray-400">
                    Fill up the details to secure your spot.
                  </p>
                </div>
                <div className="relative flex-auto">
                  <form
                    className="rounded p-6 pt-6  w-full"
                    onSubmit={submit}
                  >
                    <label className="label">Write Full Name*</label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black mb-2"
                      name="name"
                      placeholder="eg. Olivia Rhye"
                      value={formData.name}
                      onChange={handleChange}
                      type="text"
                      required
                    />
                    {touched.name && errors.name ? (
                      <p className="text-red-500 text-xs">{errors.name}</p>
                    ) : null}

                    <label className="label">Phone No.</label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black mb-2"
                      placeholder="eg. +91 12345 67890"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      type="number"
                      onKeyPress={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) &&
                        e.preventDefault()
                      }
                      required
                    />
                    {touched.mobile && errors.mobile ? (
                      <p className="text-red-500 text-xs">{errors.mobile}</p>
                    ) : null}

                    <label className="label">Write Email id*</label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black mb-2"
                      placeholder="eg. name@example.com"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      type="email"
                      required
                    />
                    {touched.email && errors.email ? (
                      <p className="text-red-500 text-xs">{errors.email}</p>
                    ) : null}

                    <div className="btnContainer">
                      <Button
                        buttonColor="white"
                        buttonText="Cancel"
                        handleFunction={closeModal}
                      />
                      <Button
                        buttonColor="purple"
                        buttonText="Submit"
                        handleFunction={submit}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormModal;
